import {
    BrowserUtils,
    ComponentElements,
    ComponentInjector,
    ComponentObject,
    ComponentSelector,
    ComponentSelectors,
    ComponentService,
    Inject,
    UIComponent,
} from "@atypon/ui-utils";

class Elements extends ComponentElements<Selectors> {
    isOpen: boolean;
    areAllOptionsChecked: boolean;
    dropdownElement: HTMLElement;
    label: HTMLElement;
    checkAllCheckbox: HTMLInputElement;
    inputs: Array<HTMLInputElement> = [];
    dropDownListItem: Array<HTMLInputElement> = [];
    searchInput: HTMLInputElement;
}

class Selectors extends ComponentSelectors {
    dropdownElement = new ComponentSelector('[data-control="checkbox-dropdown"]');
    label = new ComponentSelector(".dropdown-label");
    checkAllCheckbox = new ComponentSelector(".check-all-checkbox");
    inputs = new ComponentSelector('[type="checkbox"]');
    searchInput = new ComponentSelector('.searchInput');
    dropDownListItem = new ComponentSelector('.dropdown-list div');
}

class ClassList extends ComponentObject {
}

class Texts extends ComponentObject {
}

interface CheckboxDropdown
    extends ComponentService<Selectors, Elements, ClassList, Texts> {
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)
class CheckboxDropdown implements UIComponent {
    @Inject()
    protected browserUtils!: BrowserUtils;

    constructor(readonly wrapper: HTMLElement) {
    }

    initialize(): void {
        this.elements.initialize(this.wrapper);
        this.addEventListeners();
        this.elements.checkAllCheckbox.checked = document.querySelector('.advanced-search--refinement') === null;
    }

    addEventListeners(): void {
        this.elements.label.addEventListener("click", this.toggleOpen.bind(this));

        this.elements.dropdownElement.addEventListener("change", (e: Event) => {
            const target = e.target as HTMLInputElement;
            const clear: HTMLElement = document.querySelector('.advanced-search--refinement')
            if (target === this.elements.checkAllCheckbox) {
                if (clear) {
                    clear.remove();
                }
                this.onCheckAll();
            } else {
                this.onCheckBox();
                if(clear) {
                  this.updateRefinements(target);
                }
            }
        });
        document.addEventListener("click", this.handleGlobalClick.bind(this));

        this.elements.searchInput.addEventListener("input", this.searchThrough.bind(this))
    }


    searchThrough(input): void {
        const searchText: string = input.target.value!.toLowerCase();
        this.elements.dropDownListItem.forEach(item => {
            const label = item.textContent.toLowerCase();
            if (label.includes(searchText)) {
                item.style.display = 'block';
            } else {
                item.style.display = 'none';
            }
        });
    }

    onCheckBox(): void {
        this.updateStatus();
    }

    updateStatus(): void {
        const inputs = Array.from(this.elements.inputs) as HTMLInputElement[];
        const checked = inputs.filter((input: HTMLInputElement) => input.checked);
        const allProductsCheckbox = inputs[0];

        // if all of the checkboxes are checked then check the select all checkbox
        this.elements.areAllOptionsChecked = checked.length === inputs.length - 1;

        if (checked.length === 1 && checked[0] === allProductsCheckbox) {
            allProductsCheckbox.checked = true;
        }
        else if (this.elements.areAllOptionsChecked && checked[0] !== allProductsCheckbox) {
            allProductsCheckbox.checked = true;
            checked
                .filter((input: HTMLInputElement) => input !== allProductsCheckbox)
                .forEach((input: HTMLInputElement) => {
                    input.checked = false;
                });
        }
        else if (allProductsCheckbox.checked) {
            allProductsCheckbox.checked = false;
        }
    }

    onCheckAll(): void {
        const allProductsCheckbox = this.elements.inputs[0] as HTMLInputElement;
        const checked = Array.from(this.elements.inputs) as HTMLInputElement[];

        if (allProductsCheckbox.checked) {
            checked.filter(
                    (input: HTMLInputElement) =>
                        input !== allProductsCheckbox && input.checked
                )
                .forEach((input: HTMLInputElement) => {
                    input.checked = false;
                });
        }
        this.updateStatus();
    }

    updateRefinements(target): void {
        const parentDiv = target.parentElement;
        const textNextToInput = parentDiv.textContent.trim();
        const refinementsParent = document.querySelector('.advanced-search--refinement');
        const refinementsText = refinementsParent.querySelectorAll('li span');
        let matchingElements;

        refinementsText.forEach((element) => {
            if (element.textContent.trim() === textNextToInput) {
                matchingElements = element;
            }
        });
        if(matchingElements !== undefined) {
          matchingElements.parentElement.remove();
        }

        if (refinementsParent.querySelector('.facet__list--applied').childNodes.length === 1) {
            refinementsParent.remove();
        }
    }

    toggleOpen(): void {
        this.elements.isOpen = !this.elements.isOpen;
        this.elements.dropdownElement.classList.toggle("on");
    }

    handleGlobalClick(event: Event): void {
        const target = event.target as HTMLElement;
        const isDropdownLabel = this.elements.label.contains(target);
        const isDropdownList = this.elements.dropdownElement.contains(target);
        if (!isDropdownLabel && !isDropdownList) {
            this.closeDropdown();
        }
    }

    closeDropdown(): void {
        if (this.elements.isOpen) {
            this.elements.isOpen = false;
            this.elements.dropdownElement.classList.remove("on");
        }
    }
}

export default CheckboxDropdown;

export {
    Elements as CheckboxDropdownElements,
    Selectors as CheckboxDropdownSelectors,
    ClassList as CheckboxDropdownClassList,
    Texts as CheckboxDropdownTexts,
};
