export default class SecondaryMenu {
    constructor(menu) {
        this.menu = menu;
        this.dataDrop = this.menu?.dataset?.dropFor;
        this.isMobile = parseInt(UX.grid.screenSm) >= window.innerWidth;
        this.selector = {
            dropDownLink: '.js-secondary-menu-btn',
            dropDownMenu: '.js-secondary-menu-nav',
            title: '.js-secondary-menu-title',
            quickSearch: '.quick-search',
        };
        this.elements = {
            dropLink: this.menu.querySelector(this.selector.dropDownLink),
            dropMenu: this.menu.querySelector(this.selector.dropDownMenu),
            title: this.menu.querySelector(this.selector.title),
            quickSearch: this.menu.querySelector(this.selector.quickSearch),
        };

        this.vPort = this.elements.quickSearch ? 'screen-md' : 'screen-sm';
        this.elements.quickSearch
            ? this.menu.classList.add('secondary-menu__has-quick-search')
            : this.menu.classList.add('secondary-menu__no-quick-search');

        this.init();
    }
    init() {
        this.control();
    }

    control() {
        const _this = this;
        const doc = $(document);
        doc.on(this.vPort + '-on', function () {
            _this.isMobile = true;
            _this.build();
        });

        doc.on(this.vPort + '-off', function () {
            _this.isMobile = false;
            _this.build();
        });
        setTimeout(() => {
            this.elements.dropMenu.classList.remove('hidden');
        }, 0);
    }
    build() {
        if (this.isMobile) {
            this.buildDropDown();
        } else {
            this.DestroyDropDown();
        }
    }
    buildDropDown() {
        if (this.elements.dropLink && this.elements.title) {
            const textElement = this.elements.dropLink.querySelector('span');
            textElement.innerHTML = this.elements.title.innerHTML;
            this.elements.dropLink.style.display = 'flex';
        }
        if (this.elements.dropLink && this.elements.dropMenu && this.dataDrop) {
            this.elements.dropLink.setAttribute('data-db-target-for', this.dataDrop);
            this.elements.dropMenu.setAttribute('data-db-target-of', this.dataDrop);
        }
        UX?.dropBlock.init();
    }
    DestroyDropDown() {
        if (this.elements.dropLink && this.elements.title) {
            this.elements.dropLink.style.display = 'none';
        }
        if (this.elements.dropLink && this.elements.dropMenu) {
            this.elements.dropLink.removeAttribute('data-db-target-for');
            this.elements.dropMenu.removeAttribute('data-db-target-of');
        }
    }
}
