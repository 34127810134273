import 'ui-theme/utility/load-fonts/js/load-fonts';
import FontLoader from 'ui-core/utility/load-fonts/js/load-fonts';
const cabiFontLoader = {
    init() {
        new FontLoader('Open Sans', [400, 600, 700, 800]);
    },
};
if (UX?.fontloader) {
    UX.fontloader.init = cabiFontLoader.init;
    document.addEventListener('DOMContentLoaded', () => UX.fontloader.init());
}
