import {addEventListeners} from '../../../utility/EventListeners/eventListeners';

export default class SavedSearches {

    constructor() {
        this.init();
        this.checkSelectedTab();
    }

    private init(): void {
        addEventListeners('[data-action-type="saved-search--remove"]', 'click', (event: Event) => {
            event.preventDefault();
            let target = event.target as HTMLElement;
            while (target && target.tagName !== 'A') {
                target = target.parentElement as HTMLElement;
            }
            if (target && target.tagName === 'A') {
                const url = target.getAttribute('href');
                if (url) {
                    this.sendDeleteRequest(url);
                }
            }
        });
    }

    private sendDeleteRequest(url: string): void {
        const urlWithAjaxParam = url + (url.includes('?') ? '&' : '?') + 'ajax=true';
        const xhr = new XMLHttpRequest();
        xhr.open('GET', urlWithAjaxParam);
        xhr.onload = () => {
            if (xhr.status === 200) {
                const currentUrl = new URL(window.location.href);
                const params = new URLSearchParams(currentUrl.search);

                if (!params.has('selectedTab')) {
                    params.set('selectedTab', 'saved');
                } else {
                    params.set('selectedTab', 'saved');
                }

                currentUrl.search = params.toString();

                window.location.href = currentUrl.toString();
            }
        };
        xhr.send();
    }

    private checkSelectedTab(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedTab = urlParams.get('selectedTab');
        if (selectedTab === 'saved') {
            const savedSearchLink = document.querySelector('.tab__nav__item.tab__saved-search .tab__nav__item__link');
            if (savedSearchLink instanceof HTMLElement) {
                setTimeout(function () {
                    savedSearchLink.click();
                }, 500);
            }
        }
    }
}
