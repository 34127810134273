export default class A11y {
    constructor() {
        this.KeyCodes = {
            TAB: 9,
            RETURN: 13,
            ESC: 27,
            SPACE: 32,
            PAGEUP: 33,
            PAGEDOWN: 34,
            END: 35,
            HOME: 36,
            LEFT: 37,
            UP: 38,
            RIGHT: 39,
            DOWN: 40,
        };
        this.init();
    }
    init() {
        this.mousemoveHandlerBinded = this.mousemoveHandler.bind(this);
        this.keydownHandlerBinded = this.keydownHandler.bind(this);
        this.addEventListeners();
    }
    addEventListeners() {
        if (!this.mousemoveHandlerBinded) {
            return;
        }
        document.body.addEventListener('mousemove', this.mousemoveHandlerBinded);
    }

    mousemoveHandler() {
        document.body.classList.add('mouse-user');
        document.body.removeEventListener('mousemove', this.mousemoveHandlerBinded);
        document.body.addEventListener('keydown', this.keydownHandlerBinded);
    }

    keydownHandler(event) {
        if (!Object.values(this.KeyCodes).includes(event.keyCode)) {
            return;
        }
        document.body.classList.remove('mouse-user');
        document.body.removeEventListener('keydown', this.keydownHandlerBinded);
        document.body.addEventListener('mousemove', this.mousemoveHandlerBinded);
    }

    addSubmitOnEnter() {
        document.body.addEventListener('keydown', event => {
            if (event.key === 'Enter') {
                const target = event.target;
                if (target && target.tagName === 'INPUT' && target.type === 'search') {
                    const form = target.closest('form');
                    if (form) {
                        form.submit();
                    }
                }
            }
        });
    }
}
