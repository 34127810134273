import {addEventListeners} from '../../../utility/EventListeners/eventListeners';

export default class searchHistory {
    constructor() {
        this.init();
        this.checkSelectedTab();
    }

    private init(): void {
        //save search handler
        addEventListeners('form[name="frmSaveSearch"] .btn.primary','click', (event: Event) => {
            event.preventDefault();
            this.submitForm(event.target);
        });

        //delete search handler
        addEventListeners('.deleteSearch a', 'click', (event: Event) => {
            event.preventDefault();
            let target = event.target as HTMLElement;
            while (target && target.tagName !== 'A') {
                target = target.parentElement as HTMLElement;
            }
            if (target && target.tagName === 'A') {
                const url = target.getAttribute('href');
                if (url) {
                    this.sendDeleteRequest(url);
                }
            }
        });

        //execute query handler
        addEventListeners('.search-history .search-historyLink','click', (event: Event)=> {
            event.preventDefault();
            let target = event.target as HTMLElement;
            while (target && target.tagName !== 'A') {
                target = target.parentElement as HTMLElement;
            }
            if (target && target.tagName === 'A') {
                const url = target.getAttribute('href');
                if (url) {
                    const query = target.querySelector('.search-historyLink .hidden.query').textContent
                    let isLargeQuery = this.checkIfLargeQuery(query);
                    searchHistory.redirect(url, isLargeQuery)
                }
            }
        });
    }

    private static redirect(url: string, isLargeQuery: boolean): void {
        if (isLargeQuery === false) {
            window.location.replace(url);
        }
        else {
            const [baseUrl, queryParamsString] = url.split('?', 2);
            const form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('action', baseUrl);

            const queryParams = queryParamsString.split('&');
            for (const param of queryParams) {
                let [key, value] = param.split('=');
                if (key && value !== undefined) {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    value = value.replace(/\+/g, ' ');
                    input.value = decodeURIComponent(value);
                    form.appendChild(input);
                }
            }

            document.body.appendChild(form);
            form.submit();
        }
    }

    private checkIfLargeQuery(query: string): boolean {
        const productUrl = window.location.protocol + '//' + window.location.hostname;
        const searchAction = `/action/doSearch?AllField=`;
        const maxUrlLength = 2048;
        const fullQuery = productUrl + searchAction + encodeURIComponent(query);

        return fullQuery.length > maxUrlLength;
    }

    private submitForm($target: EventTarget): void {
        if ($target instanceof HTMLElement) {
            const form = $target.closest('form') as HTMLFormElement;
            const formData = new FormData(form);
            const alertmeRadio = form.querySelector('input[name="alertme"]:checked') as HTMLInputElement;
            const alertme = alertmeRadio ? alertmeRadio.value : '';
            const saveBtn = document.querySelector('.modal.in .btn.primary') as HTMLElement | null;
            const overlay = $target.closest('.modal__body').querySelector('.saving__overlay') as HTMLElement | null;


            overlay.classList.remove('hidden');
            formData.delete('alertme');
            formData.append('alertme', alertme);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', form.action);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            xhr.addEventListener('load', () => {
                const closeElement = document.querySelector('.modal.in .close') as HTMLElement | null;
                if (closeElement) {
                    closeElement.click();
                    overlay.classList.add('hidden');
                }
            });

            xhr.send(new URLSearchParams(formData as any).toString());
        }
    }

    private sendDeleteRequest(url: string): void {
        const urlWithAjaxParam = url + (url.includes('?') ? '&' : '?') + 'ajax=true';
        const xhr = new XMLHttpRequest();
        xhr.open('GET', urlWithAjaxParam);
        xhr.onload = () => {
            if (xhr.status === 200) {
                const currentUrl = new URL(window.location.href);
                const params = new URLSearchParams(currentUrl.search);

                if (!params.has('selectedTab')) {
                    params.set('selectedTab', 'recent');
                } else {
                    params.set('selectedTab', 'recent');
                }

                currentUrl.search = params.toString();

                window.location.href = currentUrl.toString();
            }
        };
        xhr.send();
    }

    private checkSelectedTab(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedTab = urlParams.get('selectedTab');
        if (selectedTab === 'recent') {
            const recentSearchLink = document.querySelector('.tab__nav__item.tab__search-history .tab__nav__item__link');
            if (recentSearchLink instanceof HTMLElement) {
                setTimeout(function () {
                    recentSearchLink.click();
                }, 500);
            }
        }
    }
}