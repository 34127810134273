import 'ui-core/components/article-actions-bar/js/article-action-bar';

const articleActionsbar = UX.articleActionsbar;

articleActionsbar.controls = function () {
    if (config.enableArticleActionbarSelectAll) {
        articleActionsbar.selectAllBox.addEventListener('click', function () {
            const isChecked = this.checked;
            let checkedLength = 0;
            articleActionsbar.checkboxes.forEach(function (item) {
                if (item.disabled === false) {
                    checkedLength++;
                    return (item.checked = isChecked);
                }
            });
            if (checkedLength === 0 && articleActionsbar.selectAllBox.checked && $('.ml-5.NoAccess').length === 0) {
                $('.article-actionbar__check-all').after(
                    `<span class="ml-5 NoAccess">You have no access to any of the listed records</span>`
                );
            } else if (articleActionsbar.selectAllBox.checked === false) {
                $('.ml-5.NoAccess').remove();
            }
            articleActionsbar.hideBtnsOrDisableSelect();
        });
    }

    articleActionsbar.checkboxes.forEach(elem => {
        elem.addEventListener('click', () => {
            articleActionsbar.selectAllBox.checked = articleActionsbar.allItemsSelected();
            articleActionsbar.hideBtnsOrDisableSelect();
        });
    });

    articleActionsbar.btns.forEach(elem => {
        if (!(articleActionsbar.bulkAction.length > 0)) {
            elem.addEventListener('click', function (e) {
                const _this = this;
                e.preventDefault();
                if (!_this.hasAttribute('data-toggle') && _this.nodeName !== 'OPTION') {
                    articleActionsbar.form.action = _this.href;
                    articleActionsbar.form.submit();
                }
            });
        } else {
            articleActionsbar.bulkAction.addEventListener('change', function (e) {
                const _this = this;
                if (!articleActionsbar.form.length || !articleActionsbar.hasSelectedItems()) {
                    return;
                }
                articleActionsbar.form.action = _this.value;
                articleActionsbar.form.submit();
            });
        }
    });
};
