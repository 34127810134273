import 'ui-theme/components/tabs/js/tabs';

const {tab} = UX;
tab.tabsSwiper = () => {
    const tabSwiper = document.querySelector('.tab-swiper');
    if (!tabSwiper) return;
    const tabsShowLength = 4;
    const skipLength = tabsShowLength - 1;
    const tabLists = [...tabSwiper.querySelectorAll('.tab__nav__item')];
    const swiperController = `
        <li class="arrows-slide">
            <i class="icon-arrow_l arrows-slide__single prev arrows-slide__single--disabled pr-2"></i>
            <i class="icon-arrow_r arrows-slide__single next pl-2"></i>
        </li>
    `;
    const hiddenTabs = tabLists.length > tabsShowLength ? tabLists.slice(tabsShowLength) : [];
    if (hiddenTabs.length) {
        const TabNavs = tabSwiper.querySelector('.tab__nav');
        hiddenTabs.forEach(tab => {
            tab.style.display = 'none';
        });
        TabNavs && TabNavs.insertAdjacentHTML('beforeend', swiperController);

        const controls = tabSwiper.querySelectorAll('.arrows-slide__single');

        if (controls.length) {
            controls.forEach(btn => {
                ['click', 'touchend'].forEach(event => {
                    btn.addEventListener(event, function (event) {
                        const items = [...tabSwiper.querySelectorAll('.tab__nav__item')];
                        const latestCount = items.length - 4;
                        const activeElement = items.findIndex(item => item.classList.contains('active'));
                        const isNextClicked = event.currentTarget.classList.contains('next');
                        const isPrevClicked = event.currentTarget.classList.contains('prev');
                        if (activeElement >= skipLength && isNextClicked) {
                            const item = items[activeElement - skipLength];
                            if (item) item.style.display = 'none';
                        } else if (activeElement <= latestCount && isPrevClicked) {
                            const item = items[activeElement + skipLength];
                            if (item) item.style.display = 'none';
                        }
                    });
                });
            });
        }
    }
};

window.addEventListener('load', tab.tabsSwiper, false);
