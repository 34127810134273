export default class PestAlerts {
    private recaptchaPassed: boolean;

    constructor() {
        this.recaptchaPassed = false;
        this.init();
    }

    private init(): void {
        const form = document.querySelector('.PestForm') as HTMLFormElement;
        const emailInput = document.getElementById('email') as HTMLInputElement;
        let selectElements = document.querySelectorAll('.tab__content .tab__pane.active select') as NodeListOf<HTMLSelectElement>;
        const signUpBtn = document.querySelector('.sign-up-btn') as HTMLButtonElement;
        let selectArray = Array.from(selectElements);
        const tabs = document.querySelectorAll('.tab__nav__item__link') as NodeListOf<HTMLAnchorElement>;

        tabs.forEach(tab => {
            tab.addEventListener('click', () => {
                signUpBtn.classList.add('disabled');
                signUpBtn.setAttribute('href', '#');
                setTimeout(() => {
                    selectArray.forEach(selectElement => {
                        selectElement.selectedIndex = 0;
                    });
                    selectElements = document.querySelectorAll('.tab__content .tab__pane.active select') as NodeListOf<HTMLSelectElement>;
                    selectArray = Array.from(selectElements);
                    selectArray.forEach(selectElement => {
                        selectElement.addEventListener('change', () => {
                            this.validateForm(emailInput, selectArray, signUpBtn);
                        });
                    });
                }, 500);
            });
        });

        form.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        });

        this.initializeRecaptcha();

        emailInput.addEventListener('input', () => {
            this.validateForm(emailInput, selectArray, signUpBtn);
        });

        selectArray.forEach(selectElement => {
            selectElement.addEventListener('change', () => {
                this.validateForm(emailInput, selectArray, signUpBtn);
            });
        });

        signUpBtn.addEventListener('click', (event) => {
            if (this.recaptchaPassed) {
                this.submitForm(emailInput);
            } else {
                console.error('Please complete the reCAPTCHA challenge.');
            }
        });

        this.validateForm(emailInput, selectArray, signUpBtn);
    }

    private validateForm(emailInput: HTMLInputElement, selectElements: HTMLSelectElement[], signUpBtn: HTMLButtonElement): void {
        const email = emailInput.value.trim();
        const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        let countryRegionSelected = false;

        selectElements.forEach(selectElement => {
            if (selectElement.value !== '' && selectElement.value !== 'Country' && selectElement.value !== 'Region') {
                countryRegionSelected = true;
            }
        });

        if (validEmail && countryRegionSelected && this.recaptchaPassed) {
            signUpBtn.classList.remove('disabled');
            signUpBtn.removeAttribute('href');
        } else {
            signUpBtn.classList.add('disabled');
            signUpBtn.setAttribute('href', '#');
        }
    }

    private submitForm(emailInput: HTMLInputElement): void {
        const activeDropdown = document.querySelector('.tab__content .tab__pane.active select') as HTMLSelectElement;
        const queryText = (document.querySelector('input[name="queryText"]') as HTMLInputElement).value;
        const email = encodeURIComponent(emailInput.value);
        const selectedOption = activeDropdown.options[activeDropdown.selectedIndex];
        const countryRegionValue = selectedOption.textContent === "Global" ? "" : encodeURIComponent(selectedOption.textContent);
        let key = '';

        if (activeDropdown.id === 'regionDropDown' && selectedOption.textContent !== 'Global') {
            key = '&region=';
        } else if (activeDropdown.id === 'countryDropDown') {
            key = '&country=';
        }

        const url = `/action/pestAlert?query=${queryText}&email=${email}${key}${countryRegionValue}`;

        window.location.href = url;
    }

    private initializeRecaptcha(): void {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        window['captcha_filled'] = () => {
            this.recaptchaPassed = true;
            const signUpBtn = document.querySelector('.sign-up-btn') as HTMLButtonElement;
            this.validateForm(document.getElementById('email') as HTMLInputElement, Array.from(document.querySelectorAll('.tab__content .tab__pane select')), signUpBtn);
        };
        window['captcha_expired'] = () => {
            this.recaptchaPassed = false;
            const signUpBtn = document.querySelector('.sign-up-btn') as HTMLButtonElement;
            this.validateForm(document.getElementById('email') as HTMLInputElement, Array.from(document.querySelectorAll('.tab__content .tab__pane select')), signUpBtn);
        };
    }
}
