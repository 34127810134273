export default class Menu {
    constructor(menu) {
        this.menu = menu;
        this.isMobile = parseInt(UX.grid.screenSm) >= window.innerWidth;
        this.menuBtn = this.menu.querySelector('.open-btn-menu-js');
        this.menuBtnText = this.menuBtn.querySelector('.open-menu-text-js');
        this.menuList = this.menu.querySelector('.menu-open-js');
        this.active = {
            text: {
                close: 'CLOSE',
                menu: 'MENU',
            },
            classes: {
                menu: 'menu-opened-js',
                lock: 'menu-lock-screen',
            },
        };
        this.selector = {
            focusElements: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
            loginMenu: '.main-menu__login',
        };
        this.init();
    }

    init() {
        this.openMenu();
        this.closeMenu();
    }
    openMenu() {
        ['click', 'keydown'].forEach(evt => {
            this.menuBtn.addEventListener(
                evt,
                function (event) {
                    if ((event.type === 'keydown' && event.which === 13) || event.type === 'click') {
                        const {classes, text} = this.active;
                        this.menu.classList.toggle(classes.menu);
                        const isActive = this.isMenuActive();
                        this.toggleMenuText(classes, text, isActive);
                        this.a11yBtn(isActive);
                        this.a11yFirstElementFocus();
                        document.body.classList.toggle(classes.lock);
                    }
                }.bind(this)
            );
        });
    }

    closeMenu() {
        /**
         * Buttons clicks already handled on openMenu
         * this function only to close it using ESC key [a11y] and close it the user clicks on the shadow background
         * * Esc code is 27
         */
        document.addEventListener(
            'keydown',
            function () {
                const isActive = this.isMenuActive();
                if (!isActive || event.which !== 27 || document.body.getAttribute('data-active')) {
                    return;
                }
                this.deactivateMenu();
            }.bind(this)
        );

        this.menuList.addEventListener(
            'click',
            function (event) {
                if (event.target === event.currentTarget) {
                    this.deactivateMenu();
                }
            }.bind(this)
        );
    }

    /**
     * @INFO this event to handle text on the menu button [MENU || CLOSE]
     * @param isActive true || false
     */
    toggleMenuText(classes, text, isActive) {
        if (this.menuBtnText && isActive) {
            this.menuBtnText.textContent = text.close;
        } else {
            this.menuBtnText.textContent = text.menu;
        }
    }

    /**
     * @INFO this event handle aria-pressed attribute on the menu button
     * @param isActive true || false
     */
    a11yBtn(isActive) {
        this.menuBtn.setAttribute('aria-pressed', !!isActive);
    }

    /**
     * @INFO This function to set focus on the first element once the menu opened
     */
    a11yFirstElementFocus() {
        let element = null;
        const {loginMenu, focusElements} = this.selector;
        if (this.isMobile) {
            element = this.menuList.querySelector(focusElements);
        } else {
            const selectors = focusElements
                ?.split(',')
                .map(selector => `${loginMenu} ${selector}`)
                .join(',');
            element = this.menuList.querySelector(selectors);
        }
        element?.focus();
    }

    /**
     * @INFO This function return if the Menu active or not
     * @returns {boolean}
     */
    isMenuActive() {
        return this.menu.classList.contains(this.active.classes.menu);
    }

    /**
     * @INFO This function to handle these points
     *   1- Remove active class from menu
     *   2- Change the CLOSE text to MENU
     *   3- Set aria-pressed to false on the Menu Button
     */
    deactivateMenu() {
        this.menu.classList.remove(this.active.classes.menu);
        this.toggleMenuText(this.active.classes, this.active.text, false);
        document.body.classList.remove(this.active.classes.lock);
        this.a11yBtn(false);
    }
}
