export default class SelectAll {
    /**
     * @data-target for select all checkbox
     * @data-checkbox for items checkbox
     * @INFO I filled them in Object to access them using data-target and data-checkbox
     */
    constructor(selectAllBtn) {
        if (!selectAllBtn.length) return;

        this.selectAllBtn = {};
        selectAllBtn.forEach(select => {
            this.selectAllBtn[select.dataset.target] = select;
        });

        this.checkBoxes = {};

        Object.values(this.selectAllBtn).forEach(select => {
            this.checkBoxes[select.dataset.target] = [
                ...document.querySelectorAll(`.js--search-checkbox[data-checkbox=${select.dataset.target}]`),
            ];
        });

        this.init();
    }
    init() {
        Object.values(this.selectAllBtn).forEach(select =>
            select.addEventListener('change', this.selectAll.bind(this))
        );

        Object.values(this.checkBoxes).forEach(value => {
            value.forEach(checkbox => checkbox.addEventListener('change', this.itemSelect.bind(this)));
        });
    }
    selectAll() {
        const selectItem = event.currentTarget;
        const isChecked = selectItem.checked;
        const target = selectItem.dataset.target;
        this.checkBoxes[target].forEach(checkbox => (checkbox.checked = isChecked));
    }
    itemSelect() {
        const target = event.currentTarget.dataset.checkbox;
        this.selectAllBtn[target].checked = this.allItemsSelected(target);
    }
    allItemsSelected(target) {
        return this.checkBoxes[target].every(item => {
            return item.checked;
        });
    }
}
