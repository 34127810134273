import { ComponentSelector, ComponentInjector } from '@atypon/ui-utils';

import CoreUiTooltip, {
    UITooltipElements,
    UITooltipSelectors,
    UITooltipClassList,
    UITooltipTexts
} from 'ui-core/plugins/ui-tooltip/js/ui-tooltip';

class Texts extends UITooltipTexts {

}

class ClassList extends UITooltipClassList {

}

class Selectors extends UITooltipSelectors {
    tooltips = new ComponentSelector('[data-toggle="tooltip"]', 'document');
}

class Elements extends UITooltipElements {

}

interface UITooltip {
    get elements(): Elements;
    get texts(): Texts;
    get classList(): ClassList;
    get selectors(): Selectors;
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)
class UITooltip extends CoreUiTooltip {}

export default UITooltip;

export {
    Selectors as QuickSearchSelectors,
    Elements as QuickSearchElements,
    ClassList as QuickSearchClassList,
    Texts as QuickSearchTexts,
};
